import React from 'react'
import './css/index.css'
import logo from './images/logo.png'
import cqcLogo from './images/cqc-no-background.png'
import Vimeo from '@u-wave/react-vimeo'

const App = () => {
  return (
    <div className="text-center min-h-screen w-screen bg-orriyellow-light guest-bg-image bg-top md:bg-center flex flex-col justify-center items-center p-4 md:p-8">
      <div className="w-full md:max-w-sm flex flex-col items-center h-full">
        <img className="mt-0 h-20 w-20 mb-3" src={logo} alt="ORRI Logo" />
        <div className="text-gray-500 flex flex-col mb-8">
          <span>The ORRI website is offline for maintenance.</span>
          <span>We'll be back shortly.</span>
        </div>
      </div>
      <div className="flex flex-col w-full md:w-xl max-w-xl">
        <div className="font-semibold mb-2">What makes ORRI different?</div>
        <Vimeo
          video="428525885"
          showTitle={false}
          showByline={false}
          responsive={true}
        />
        <div className="font-light mt-6 px-3 flex flex-col space-y-0">
          <span>Contact us for more information:</span>
          <a
            href="mailto:askorri@orri-uk.com"
            className="underline text-indigo-400"
          >
            askorri@orri-uk.com
          </a>
        </div>
      </div>
      <a
        href="https://www.cqc.org.uk/location/1-6381661318"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={cqcLogo}
          className="my-6 mt-8 w-44 md:w-32"
          alt="Care Quality Commission : Outstanding"
        />
      </a>
    </div>
  )
}

export default App
